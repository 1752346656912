import styled, { css } from "styled-components"
import CustomButton from "../custom-button/custom-button.component"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import Container from "@mui/material/Container"
import * as Logo from "../../assets/icons/A_horz_logo.svg"
import { Grid } from "@mui/material"
import CustomLink from "../custom-link/custom-link.component"

export const LogoDesign = css`
  color: ${({ theme }) => theme.palette.text.primary};
`
export const underline = css`
  a {
    &.active {
      &:after {
        width: 100%;
      }
    }
  }

  a {
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;

    &:after {
      transition: all 0.3s ease;
      content: "";
      position: absolute;
      height: 1px;
      background-color: ${({ theme }) => theme.palette.secondary.main};

      bottom: -4px;
      width: 0;
      left: 0;
    }
  }
  &:hover:not(.logo) {
    a {
      &:after {
        width: 100%;
      }
    }
  }
`

export const Wrapper = styled.footer`
  color: ${({ theme }) => theme.palette.secondary.main};
  padding-top: ${({ theme }) => theme.typography.pxToRem(100)};
  background-color: ${({ theme }) => theme.palette.primary.main};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-top: 1.5rem;
  }
`
export const DescriptionSection = styled.div``
export const Description = styled.h2`
  font-size: ${({ theme }) => theme.typography.pxToRem(48)};
  line-height: ${({ theme }) => theme.typography.pxToRem(60)};
  color: ${({ theme }) => theme.palette.primary.light};
  text-align: center;
  max-width: 1100px;
  margin: auto;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(24)};
    line-height: ${({ theme }) => theme.typography.pxToRem(32)};
  }
`
export const ButtonDiv = styled.div`
  margin-top: 3rem;
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: 2.5rem;
  }
`
export const Button = styled(CustomButton)``
export const BottomItems = styled.div`
  border-top: 1px solid #3c3c3c;
`
export const GridContainer = styled(Grid)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`
export const ItemsContainer = styled(Container)``
export const AddressWrapper = styled(Grid)`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    justify-content: center;
  }
  ${underline}
`
export const Place = styled.p`
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  line-height: ${({ theme }) => theme.typography.pxToRem(21)};
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: 0;
  margin-top: 0;
`
export const Mail = styled.a`
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  line-height: ${({ theme }) => theme.typography.pxToRem(21)};
  color: ${({ theme }) => theme.palette.primary.light};
  margin-bottom: 0;
  margin-top: 0;
  text-decoration: none;
`
export const SocialNetworkWrapper = styled(Grid)``
export const TextContainer = styled.div`
  max-width: 185px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: 186px;
  }
`
export const LinkContainer = styled.div`
  ${underline}
`
export const Link = styled(CustomLink)`
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  line-height: ${({ theme }) => theme.typography.pxToRem(14)};
  letter-spacing: ${({ theme }) => theme.typography.pxToRem(0.1)};
`
export const Facebook = styled(FacebookIcon)`
  ${LogoDesign}
`
export const Instagram = styled(InstagramIcon)`
  ${LogoDesign}
`
export const LinkedIn = styled(LinkedInIcon)`
  ${LogoDesign}
`

export const CopyrightWrapper = styled(Grid)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
  }
`
export const CopyrightDescription = styled.p`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  line-height: ${({ theme }) => theme.typography.pxToRem(21)};
`
export const LogoContainer = styled.div``
export const AftershockLogo = styled(Logo)`
  max-width: 150px;
  width: 100%;
`
