import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import {
  getWordpressToken,
  isBrowser,
  setFormUtmParams,
  submitHubspotForm,
} from "../../utils"
import { Grid } from "@mui/material"
import CustomInput from "../custom-input/custom-input.component"
import { Typography } from "@mui/material"
import { contactFormApi, mediaApi } from "../../apis/apis"

import * as S from "./career-apply-form.styles"

const CareerApplyForm = ({ setSuccessMessage }) => {
  const portalId = "test"
  const formId = "test"
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [CVLink, setCVLink] = useState("")
  const [cvErrorMessage, setCVErrorMessage] = useState("")

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  })

  const handleUploadFile = async file => {
    if (file && file[0]) {
      setIsLoading(true)
      const token = await getWordpressToken()
      const formData = new FormData()
      formData.append("file", file[0])
      const response = await mediaApi.post(`/wp/v2/media`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })

      if (response.data.source_url) {
        setCVLink(response.data.source_url)
        setIsLoading(false)
      }
    }
  }

  const onSubmit = async data => {
    if (CVLink) {
      setErrorMessage("")
      setIsLoading(true)

      const form = new FormData()
      form.append("yourName", data.fullname)
      form.append("yourEmail", data.email)
      form.append("yourPhone", data.phone)
      if (CVLink) form.append("yourCV", CVLink)
      const token = await getWordpressToken()

      await mediaApi
        .post(`/contact-form-7/v1/contact-forms/762/feedback`, form, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(response => {
          setIsLoading(false)
          if (response?.data?.message) {
            setSuccessMessage(
              "Gracias por su mensaje. Ha sido enviado correctamente"
            )
          } else {
            setErrorMessage("Ha ocurrido un error, vuelva a intentarlo")
          }
        })
    } else {
      setCVErrorMessage("Este campo es requerido")
    }
  }

  useEffect(() => {
    setFormUtmParams(setValue)
  }, [])
  return (
    <form
      data-form-id={formId}
      data-portal-id={portalId}
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "100%" }}
    >
      {isLoading && (
        <S.FormSpinner>
          <S.CustomSpinner />
        </S.FormSpinner>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomInput
            register={register}
            label="Nombre Completo"
            isRequired
            errors={errors}
            name="fullname"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomInput
            register={register}
            label="Correo"
            isRequired
            errors={errors}
            name="email"
            type="email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomInput
            register={register}
            label="Teléfono"
            isRequired
            errors={errors}
            name="phone"
            type="tel"
          />
        </Grid>
        <Grid item xs={12}>
          <S.CustomDropzoneArea
            acceptedFiles={["application/*"]}
            filesLimit={1}
            multiple={false}
            dropzoneText={"Arrastra y Suelta tu CV"}
            onChange={handleUploadFile}
            onDrop={() => setCVLink("")}
            onDelete={() => setCVLink("")}
            dropzoneClass="no-background"
          />
          {cvErrorMessage && <S.AlertMessage>{cvErrorMessage}</S.AlertMessage>}
        </Grid>
      </Grid>
      {errorMessage && (
        <Typography style={{ marginTop: "2em" }}>
          <b>{errorMessage}</b>
        </Typography>
      )}
      <S.SubmitBtnWrapper>
        <S.CustomButton type="submit">Enviar</S.CustomButton>
      </S.SubmitBtnWrapper>
    </form>
  )
}
export default CareerApplyForm
