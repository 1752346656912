import styled from "styled-components"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import CustomButton from "../../custom-button/custom-button.component"
import CustomLink from "../../custom-link/custom-link.component"
import { Typography } from "@mui/material"

export const ItemsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props =>
    props.blackHeader
      ? ({ theme }) => theme.palette.primary.main
      : ({ theme }) => theme.palette.primary.light};
  color: white;
  padding: 2em 1em;
  z-index: 9999;
  width: 100%;
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  //.search {
  //  margin-bottom: 1em;
  //}
  //margin-top: 2rem;
`

export const InnerWrapper = styled.div``
export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: center;
  //align-items: center;
  //padding: 0.5rem 0;
`
export const Item = styled(CustomLink)`
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  line-height: ${({ theme }) => theme.typography.pxToRem(49)};
  color: ${props =>
    props.blackHeader
      ? ({ theme }) => theme.palette.primary.light
      : ({ theme }) => theme.palette.primary.main};
  &.es {
    margin-left: 1rem;
    text-decoration: underline;
  }
`

export const MenuIcon = styled(IconButton)`
  z-index: 1;
`

export const ButtonWrapper = styled.div`
  margin-bottom: 1em;
  text-align: center;
`

export const Button = styled(CustomButton)`
  width: 100%;
  background-color: black;
  text-transform: none;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.02em;
  line-height: 120%;
  min-height: 45px;
`
export const InfoWrapper = styled.div``
export const Description = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  line-height: ${({ theme }) => theme.typography.pxToRem(32)};
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.text.primary};
  span {
    color: ${props =>
      props.blackHeader
        ? ({ theme }) => theme.palette.primary.light
        : ({ theme }) => theme.palette.primary.main};
  }
`
