import React, { useContext } from "react"
import { Fade } from "@mui/material"
import TextLoop from "react-text-loop"

import * as S from "./header-mobile.styles.jsx"
import { DrawerContext } from "../../../provider"

const HeaderMobile = ({
  items,
  children,
  isActiveMenu,
  blackHeader,
  englishUrl,
}) => {
  const { setIsFormDrawerOpen } = useContext(DrawerContext)
  return (
    <Fade in={isActiveMenu} mountOnEnter unmountOnExit>
      <S.ItemsWrapper id="mobileItemsWrapper" blackHeader={blackHeader}>
        <S.InnerWrapper>
          {children}
          {items.map((item, index) => (
            <S.ItemContainer key={`${index} - ${item?.title}`}>
              <S.Item url={item?.url} blackHeader={blackHeader}>
                {item.title}
              </S.Item>
            </S.ItemContainer>
          ))}
          <S.ItemContainer>
            <S.Item
              onClick={() => setIsFormDrawerOpen(true)}
              blackHeader={blackHeader}
            >
              Contáctanos
            </S.Item>
          </S.ItemContainer>
        </S.InnerWrapper>
        <S.InfoWrapper>
          <S.Item url={englishUrl} blackHeader={blackHeader}>
            EN
          </S.Item>
          <S.Item blackHeader={blackHeader} className="es">
            ES
          </S.Item>
          <S.Description blackHeader={blackHeader}>
            Deja que te ayudemos a crear algo que a tus
            <TextLoop>
              <span>&nbsp;consumidores</span>
              <span>&nbsp;usuarios</span>
              <span>&nbsp;personas</span>
              <span>&nbsp;aficionados</span>
              <span>&nbsp;enemigos</span>
              <span>&nbsp;haters</span>
              <span>&nbsp;competidores</span>
              <span>&nbsp;partidarios</span>
              <span>&nbsp;vecinos</span>
              <span>&nbsp;trolls</span>
              <span>&nbsp;clientes</span>
              <span>&nbsp;ejecutivos</span>
              <span>&nbsp;gestores</span>
              <span>&nbsp;entusiastas</span>
              <span>&nbsp;puristas</span>
              <span>&nbsp;niños</span>
              <span>&nbsp;meseros</span>
              <span>&nbsp;compañeros</span>
            </TextLoop>{" "}
            les gustará
          </S.Description>
        </S.InfoWrapper>
      </S.ItemsWrapper>
    </Fade>
  )
}
export default HeaderMobile
