import React, { useContext, useState } from "react"
import * as NavItems from "./header.json"
import { Hidden, Slide, useScrollTrigger } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import MenuIcon from "@mui/icons-material/Menu"
import CustomLink from "../custom-link/custom-link.component"
import Container from "@mui/material/Container"
import PropTypes from "prop-types"
import { DrawerContext } from "../../provider"
import HeaderMobile from "./header-mobile/header-mobile.component"

import * as S from "./header.styles.jsx"

function HideOnScroll(props) {
  const { children, window, isMenu } = props
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  })

  return (
    <Slide appear={false} direction="down" in={!trigger || isMenu}>
      {children}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

const Header = ({ blackHeader, englishUrl }, props) => {
  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 102,
  })
  const [isActiveMenu, setIsActiveMenu] = useState(false)
  const handleToggleMenu = () => {
    setIsActiveMenu(!isActiveMenu)
  }
  const { setIsFormDrawerOpen } = useContext(DrawerContext)

  return (
    <S.Section>
      <HideOnScroll {...props} isMenu={isActiveMenu}>
        <S.CustomAppBar
          bgColor={blackHeader}
          position="fixed"
          elevation={scrollTrigger ? 4 : 0}
        >
          <Container maxWidth="xl">
            <S.MainNav resize={scrollTrigger}>
              <CustomLink url="/">
                <S.AftershockLogo color={blackHeader} />
              </CustomLink>
              <Hidden mdDown>
                <S.ItemsContainer sx={{ marginLeft: "auto" }}>
                  <S.Item color={blackHeader}>
                    <CustomLink url="/quienes-somos">Quiénes somos</CustomLink>
                  </S.Item>
                  <S.Item color={blackHeader}>
                    <CustomLink url="/trabajos">Trabajos</CustomLink>
                  </S.Item>
                  <S.Item color={blackHeader}>
                    <CustomLink url="/servicios">Servicios</CustomLink>
                  </S.Item>
                  <S.Item color={blackHeader}>
                    <CustomLink url="/vacantes">Vacantes</CustomLink>
                  </S.Item>
                  <S.Item color={blackHeader} className="en">
                    <CustomLink url={englishUrl}>EN</CustomLink>
                  </S.Item>
                  <S.Item color={blackHeader} className="es">
                    <p>ES</p>
                  </S.Item>
                  <S.Button
                    textColor={blackHeader}
                    onClick={() => setIsFormDrawerOpen(true)}
                    blackHeader={blackHeader}
                  >
                    Contáctanos
                  </S.Button>
                </S.ItemsContainer>
              </Hidden>
              <div style={{ display: "flex" }}>
                <Hidden mdUp>
                  <S.MenuButton onClick={handleToggleMenu}>
                    {isActiveMenu ? (
                      <CloseIcon
                        style={{
                          color: blackHeader ? "#F8F8F8" : "#1C1D1F",
                        }}
                      />
                    ) : (
                      <MenuIcon
                        style={{
                          color: blackHeader ? "#F8F8F8" : "#1C1D1F",
                        }}
                      />
                    )}
                  </S.MenuButton>
                </Hidden>
              </div>
            </S.MainNav>
          </Container>
          <HeaderMobile
            isActiveMenu={isActiveMenu}
            handleClose={() => setIsActiveMenu(false)}
            items={NavItems.mainItems}
            blackHeader={blackHeader}
            englishUrl={englishUrl}
          />
        </S.CustomAppBar>
      </HideOnScroll>
    </S.Section>
  )
}
export default Header
