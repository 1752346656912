import styled from "styled-components"
import CustomInput from "../custom-input/custom-input.component"
import { Box } from "@mui/system"
import { Button, CircularProgress } from "@mui/material"
import { DropzoneArea } from "mui-file-dropzone"

export const SubmitBtnWrapper = styled.div`
  //text-align: right;
  margin-top: 1em;
`
export const CustomButton = styled(Button)`
  padding: 0.5em 3em;
  border: 1px solid white;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  font-weight: 500 !important;
  border-radius: ${({ theme }) => theme.typography.pxToRem(24)};
  text-transform: unset;
  color: white;
  background-color: transparent;
  &:hover {
    background-color: white !important;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
export const MessageInput = styled(CustomInput)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 2em;
  }
`

export const FormSpinner = styled(Box)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(0,0,0,0.1);
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`

export const CustomSpinner = styled(CircularProgress)`
  color: white;
  width: 60px !important;
  height: 60px !important;
`

export const CustomDropzoneArea = styled(DropzoneArea)`
  margin-top: 1em;
`

export const AlertMessage = styled.p`
  color: red;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  margin-top: 3px;
`
