/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import SEO from "../components/seo/seo.component"
import Header from "../components/header/header.component"
import { AppContainer } from "../styles/app.styles"
import Footer from "./footer/footer.component"
import LetsTalkDrawer from "./lets-talk-drawer/lets-talk-drawer.component"
import CareerApplyDrawer from "./career-apply-drawer/career-apply-drawer.component"

const Layout = ({ seo, children, blackHeader, pageName, englishUrl }) => {
  return (
    <>
      {seo && <SEO data={seo} />}
      <LetsTalkDrawer />
      <CareerApplyDrawer />

      <Header blackHeader={blackHeader} englishUrl={englishUrl} />
      <AppContainer bgColor={pageName === "Home"}>{children}</AppContainer>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
