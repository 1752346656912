import React from "react"
import PropTypes from "prop-types"
import * as S from "./custom-link.styles"
import { useTheme } from "@mui/system"

const CustomLink = ({ url, children, ...otherProps }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(url)
  const file = /\.[0-9a-z]+$/i.test(url)
  const theme = useTheme()

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    if (file) {
      return (
        <S.CustomRegularLink href={url} {...otherProps}>
          {children}
        </S.CustomRegularLink>
      )
    }
    return (
      <S.CustomAniLink
        cover
        direction="right"
        to={url}
        duration={2}
        bg={theme.palette.primary.main}
        partiallyActive={true}
        activeClassName="active"
        {...otherProps}
      >
        {children}
      </S.CustomAniLink>
    )
  }

  // <S.CustomAniLink
  //   fade to={url}
  //>
  //   {children}
  // </S.CustomAniLink

  return (
    <S.CustomRegularLink href={url} {...otherProps}>
      {children}
    </S.CustomRegularLink>
  )
}

CustomLink.propTypes = {
  url: PropTypes.string.isRequired,
  target: PropTypes.string,
}

export default CustomLink
