import React, { useContext } from "react"
import Container from "@mui/material/Container"
import { DrawerContext } from "../../provider"

import * as S from "./footer.styles"

const Footer = ({ className }) => {
  const { setIsFormDrawerOpen } = useContext(DrawerContext)
  return (
    <S.Wrapper className={className}>
      <Container maxWidth="xl">
        <S.DescriptionSection>
          <S.Description>
            Si buscas un diseño inspirado, aspiras la excelencia digital y
            anhelas una empresa de profesionales comprometidos...
          </S.Description>
          <S.ButtonDiv>
            <S.Button
              onClick={() => setIsFormDrawerOpen(true)}
              className="lightBorder"
            >
              Contáctanos
            </S.Button>
          </S.ButtonDiv>
        </S.DescriptionSection>
      </Container>
      <S.BottomItems>
        <S.ItemsContainer maxWidth="xl">
          <S.GridContainer
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <S.AddressWrapper item xs={12} md={4}>
              {/*<S.Place>Hollywood</S.Place>*/}
              <S.Place>Escríbenos a</S.Place>
              <S.Mail href="mailto:hello@aftershock.agency">
                hello@aftershock.agency
              </S.Mail>
            </S.AddressWrapper>
            <S.SocialNetworkWrapper item xs={12} md={4} className="space">
              <S.TextContainer>
                <S.LinkContainer>
                  <S.Link
                    url="https://www.linkedin.com/company/aftershockinc/"
                    target="_blank"
                  >
                    LinkedIn
                  </S.Link>
                </S.LinkContainer>
                <S.LinkContainer>
                  <S.Link
                    url="https://www.instagram.com/aftershock.agency/"
                    target="_blank"
                  >
                    Instagram
                  </S.Link>
                </S.LinkContainer>
              </S.TextContainer>
            </S.SocialNetworkWrapper>
            <S.CopyrightWrapper item xs={12} md={4} className="last">
              <S.CopyrightDescription>
                © Copyright Aftershock Inc. {new Date().getFullYear()}
              </S.CopyrightDescription>
              <S.AftershockLogo />
            </S.CopyrightWrapper>
          </S.GridContainer>
        </S.ItemsContainer>
      </S.BottomItems>
    </S.Wrapper>
  )
}

export default Footer
